import React, { Suspense, StrictMode } from 'react';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './App.scss';
import combineReducer from './State/Reducers/index';
import Screen from './Components/Screen';
import ScreenLoader from './Components/ScreenLoader';
import MaintenanceScreen from './Screens/MaintenanceScreen';
import { initializeGoogleAnalytics } from './Util/analytics';
import { isTokenExpired } from './Util/authentication';

// Screens
const LandingScreen = React.lazy(() => import('./Screens/LandingScreen'));
const LoginScreen = React.lazy(() => import('./Screens/LoginScreen'));
const AccountActivationScreen = React.lazy(() => import('./Screens/AccountActivationScreen'));
const RegistrationScreen = React.lazy(() => import('./Screens/RegistrationScreen'));
const ImprintScreen = React.lazy(() => import('./Screens/ImprintScreen'));
const ApplyScreen = React.lazy(() => import('./Screens/ApplyScreen'));
const LetterScreen = React.lazy(() => import('./Screens/LetterScreen'));
const ResumeScreen = React.lazy(() => import('./Screens/ResumeScreen'));
const DesignScreen = React.lazy(() => import('./Screens/DesignScreen'));
const CheckoutScreen = React.lazy(() => import('./Screens/CheckoutScreen'));
const ThankYouScreen = React.lazy(() => import('./Screens/ThankYouScreen'));
const FeedbackScreen = React.lazy(() => import('./Screens/FeedbackScreen'));
const AccountScreen = React.lazy(() => import('./Screens/AccountScreen'));
const ApplicationsScreen = React.lazy(() => import('./Screens/ApplicationsScreen'));
const FAQScreen = React.lazy(() => import('./Screens/FAQScreen'));
const DataPrivacyScreen = React.lazy(() => import('./Screens/DataPrivacyScreen'));
const ForgotPasswordScreen = React.lazy(() => import('./Screens/ForgotPasswordScreen'));
const RecoverPasswordScreen = React.lazy(() => import('./Screens/RecoverPasswordScreen'));
const NotFoundScreen = React.lazy(() => import('./Screens/NotFoundScreen'));
const ThemeListScreen = React.lazy(() => import('./Screens/ThemeListScreen'));
const ThemeScreen = React.lazy(() => import('./Screens/ThemeScreen'));
const BundleScreen = React.lazy(() => import('./Screens/BundleScreen'));
const ErrorScreen = React.lazy(() => import('./Screens/ErrorScreen'));
const ContactScreen = React.lazy(() => import('./Screens/ContactScreen'));
const DownloadScreen = React.lazy(() => import('./Screens/DownloadScreen'));
const PaymentListScreen = React.lazy(() => import('./Screens/PaymentListScreen'));
const TermsAndConditionsScreen = React.lazy(() => import('./Screens/TermsAndConditionsScreen'));
const ConfirmEmailScreen = React.lazy(() => import('./Screens/ConfirmEmailScreen'));
const TextSuggestionScreen = React.lazy(() => import('./Screens/TextSuggestionScreen'));
const PartnerScreen = React.lazy(() => import('./Screens/PartnerScreen'));

const persistConfig = {
  key: process.env.REACT_APP_BUILD_VERSION,
  storage: storage,
  blacklist: ['notifications'],
};
const persistedReducer = persistReducer(persistConfig, combineReducer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const store: any = createStore(
  persistedReducer,
  // https://github.com/zalmoxisus/redux-devtools-extension#11-basic-store
  window['__REDUX_DEVTOOLS_EXTENSION__'] && window['__REDUX_DEVTOOLS_EXTENSION__']()
);

const persistor = persistStore(store);

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = isTokenExpired() === false;

  if (isLoggedIn === false) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const history = createBrowserHistory({ window });

const AppRoutes = () => (
  <HistoryRouter history={history}>
    <Routes>
      <Route path="/" element={<Screen />}>
        <Route path="/" element={<LandingScreen />} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="activate/:id" element={<AccountActivationScreen />} />
        <Route path="confirm-email/:id" element={<ConfirmEmailScreen />} />
        <Route path="register" element={<RegistrationScreen />} />
        <Route path="password" element={<ForgotPasswordScreen />} />
        <Route path="password-recovery/:token" element={<RecoverPasswordScreen />} />
        <Route path="faq" element={<FAQScreen />} />
        <Route path="themes" element={<ThemeListScreen />} />
        <Route path="themes/:slug" element={<ThemeScreen />} />
        <Route path="bundles" element={<BundleScreen />} />
        <Route path="contact" element={<ContactScreen />} />
        <Route path="imprint" element={<ImprintScreen />} />
        <Route path="data-privacy" element={<DataPrivacyScreen />} />
        <Route path="terms-and-conditions" element={<TermsAndConditionsScreen />} />
        <Route path="text-suggestions/*" element={<TextSuggestionScreen />} />
        <Route path="partner/jp-associates" element={<PartnerScreen />} />
        <Route path="error" element={<ErrorScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
        <Route
          path="account"
          element={
            <ProtectedRoute>
              <AccountScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="payments"
          element={
            <ProtectedRoute>
              <PaymentListScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="applications"
          element={
            <ProtectedRoute>
              <ApplicationsScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="apply/:id"
          element={
            <ProtectedRoute>
              <ApplyScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="apply/:id/design/*"
          element={
            <ProtectedRoute>
              <DesignScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="apply/:id/download/*"
          element={
            <ProtectedRoute>
              <DownloadScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="apply/:id/feedback"
          element={
            <ProtectedRoute>
              <FeedbackScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="checkout/*"
          element={
            <ProtectedRoute>
              <CheckoutScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="thank-you"
          element={
            <ProtectedRoute>
              <ThankYouScreen />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="apply/:id/letter/*"
        element={
          <ProtectedRoute>
            <LetterScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="apply/:id/resume/*"
        element={
          <ProtectedRoute>
            <ResumeScreen />
          </ProtectedRoute>
        }
      />
    </Routes>
  </HistoryRouter>
);

class App extends React.PureComponent {
  componentDidMount(): void {
    initializeGoogleAnalytics();
  }

  public render() {
    return (
      <StrictMode>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={<ScreenLoader />}>
              <Suspense fallback={<ScreenLoader />}>
                {process.env.REACT_APP_MAINTENANCE === 'true' ? <MaintenanceScreen /> : <AppRoutes />}
              </Suspense>
            </PersistGate>
          </Provider>
        </GoogleOAuthProvider>
      </StrictMode>
    );
  }
}

export default Sentry.withErrorBoundary(App, {
  fallback: (
    <Suspense fallback={<ScreenLoader />}>
      <ErrorScreen />
    </Suspense>
  ),
});
