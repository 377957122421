// Actions
export const SET_JOB_APPLICATIONS = 'SET_JOB_APPLICATIONS';
export const UPDATE_JOB_APPLICATION = 'UPDATE_JOB_APPLICATION';
export const REMOVE_JOB_APPLICATION = 'REMOVE_JOB_APPLICATION';

// Action creators
export const setJobApplications = (jobApplications) => ({
  type: SET_JOB_APPLICATIONS,
  jobApplications,
});

export const updateJobApplication = (updatedJobApplication) => ({
  type: UPDATE_JOB_APPLICATION,
  updatedJobApplication,
});

export const removeJobApplication = (jobApplicationId) => ({
  type: REMOVE_JOB_APPLICATION,
  jobApplicationId,
});
