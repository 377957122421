import React from 'react';
import { ReactComponent as LogoBasic } from '../assets/logo.svg';
import { ReactComponent as LogoWithSlogan } from '../assets/logo_with_slogan.svg';

interface LogoProps {
  slogan?: boolean;
  className?: string;
}

const Logo = ({ slogan = false, className = '' }: LogoProps) =>
  slogan ? <LogoWithSlogan className={className} /> : <LogoBasic className={className} />;

export default Logo;
