import { LOGOUT_USER, SET_TOKEN, SET_USER } from '../Actions/user';
import { setUserId } from '../../Util/analytics';

const initialState = {
  token: null,
  firstName: null,
  lastName: null,
  email: null,
  newEmail: null,
  externalType: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      const { token } = action;

      return {
        ...state,
        token,
      };
    }
    case SET_USER: {
      const { user } = action;

      setUserId(user.id);

      return {
        ...state,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        newEmail: user.newEmail,
        externalType: user.externalType,
      };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
