import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

const NavLink = ({ to, children, className, activeClassName = 'active' }) => (
  <RouterNavLink to={to} className={({ isActive }) => (isActive ? `${className} ${activeClassName}` : className)}>
    {children}
  </RouterNavLink>
);

export default NavLink;
