var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowRightLight } from "./svgs/svg-as-component";
import DateInput from "./DateInput";
import Checkbox from "./Checkbox";
var DateRangeInput = function (props) { return (_jsxs("fieldset", __assign({ className: "d-flex flex-column flex-md-row" }, { children: [_jsx("div", __assign({ className: "w-100" }, { children: _jsx(DateInput, { label: "Von (MM.JJJJ)", id: props.startId, value: props.startValue, touched: props.startTouched, error: props.startError, onChange: function (event) {
                    var start = event.target.value;
                    props.onChangeStart(start);
                }, onBlur: props.onBlur, datePattern: ["m", "Y"] }) })), _jsx(ArrowRightLight, { width: 20, height: 20, className: "mx-2 flex-shrink-0 d-none d-md-block", style: { marginTop: 19 } }), _jsxs("div", __assign({ className: "w-100" }, { children: [_jsx(DateInput, { label: "Bis (MM.JJJJ)", id: props.endId, value: props.endValue, touched: props.endTouched, error: props.endError, onChange: function (event) {
                        var end = event.target.value;
                        props.onChangeEnd(end);
                    }, onBlur: props.onBlur, datePattern: ["m", "Y"], required: false, disabled: props.untilTodayValue === true }), _jsx(Checkbox, { id: "".concat(props.startId, "_").concat(props.endId, "_untilToday"), label: "Bis heute", checked: props.untilTodayValue, touched: true, error: undefined, onChange: function () {
                        props.onChangeUntilToday(!props.untilTodayValue);
                        props.onChangeEnd("");
                    } })] }))] }))); };
export default DateRangeInput;
