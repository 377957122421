var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownLight } from "./svgs/svg-as-component";
var MoveButtons = function (_a) {
    var onUp = _a.onUp, onDown = _a.onDown, isUpEnabled = _a.isUpEnabled, isDownEnabled = _a.isDownEnabled;
    return (_jsxs("div", __assign({ className: "d-inline-flex flex-column" }, { children: [_jsx("button", __assign({ type: "button", className: "btn btn-sm shadow-none py-0", title: "Nach oben verschieben", onClick: onUp, disabled: isUpEnabled === false }, { children: _jsx(ChevronDownLight, { height: 12, style: { transform: "rotate(180deg)" } }) })), _jsx("button", __assign({ type: "button", className: "btn btn-sm shadow-none py-0", title: "Nach unten verschieben", onClick: onDown, disabled: isDownEnabled === false }, { children: _jsx(ChevronDownLight, { height: 12 }) }))] })));
};
export default MoveButtons;
