import jwtDecode, { JwtPayload } from 'jwt-decode';
import { NormalizedUser } from '@apply-high/interfaces';
import { history, store } from '../App';
import { logoutUser } from '../State/Actions/user';
import { trackEvent } from './analytics';

export enum LOGIN_TRANSITION {
  SESSION_EXPIRED = 'from_session_expired',
  ACCOUNT_DELETION = 'from_account_deletion',
  AFTER_REGISTRATION = 'from_registration',
  PASSWORD_RECOVERY = 'from_password_recovery',
}

type TokenData = JwtPayload & NormalizedUser;

export const decodeJwtToken = (token: string): TokenData => {
  return jwtDecode<TokenData>(token);
};

export const isTokenExpired = (): boolean => {
  const { user } = store.getState();
  const { token } = user;

  if (token === null) {
    return true;
  }

  const { exp } = decodeJwtToken(token);

  return Date.now() >= exp * 1000;
};

export const triggerLogout = (redirectToLogin = false) => {
  store.dispatch(logoutUser());
  trackEvent('user_logout');

  if (redirectToLogin === true) {
    history.push('/login');
  }
};
