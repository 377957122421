import { NormalizedUser } from '@apply-high/interfaces';

// Actions
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

// Action creators
export const setToken = (token: string) => ({
  type: SET_TOKEN,
  token,
});

export const setUser = (user: NormalizedUser) => ({
  type: SET_USER,
  user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});
