var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "./Button";
import { EditLight, TrashAltLight } from "./svgs/svg-as-component";
var ListEntry = function (_a) {
    var header = _a.header, subHeader = _a.subHeader, onEdit = _a.onEdit, onRemove = _a.onRemove;
    return (_jsxs("div", __assign({ className: "d-flex align-items-center justify-content-between py-2" }, { children: [_jsxs("div", { children: [_jsx("div", { children: header }), _jsx("div", __assign({ className: "text-muted fw-light" }, { children: subHeader }))] }), _jsxs("div", __assign({ className: "d-flex" }, { children: [onEdit && (_jsx(Button, __assign({ circled: true, variant: "easy", title: "Bearbeiten", className: "me-1", onClick: onEdit }, { children: _jsx(EditLight, { width: 20 }) }))), onRemove && (_jsx(Button, __assign({ circled: true, variant: "easy", title: "Entfernen", onClick: onRemove }, { children: _jsx(TrashAltLight, { width: 16 }) })))] }))] })));
};
export default ListEntry;
