var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { EyeRegular, EyeSlashRegular } from "./svgs/svg-as-component";
import Button from "./Button";
// Applies bootstrap form-control related classes for certain states of the input
export var getClassNames = function (type, touched, error) {
    var classNames = ["form-control"];
    if (touched === true && error !== undefined) {
        classNames.push("is-invalid");
    }
    if (touched === true && error === undefined) {
        classNames.push("is-valid");
    }
    // Prevent validation icons to be displayed above password visibility icon
    if (type === "password") {
        classNames.push("bg-image-none");
    }
    return classNames.join(" ");
};
var getType = function (type, isPasswordVisible) {
    if (type === "password" && isPasswordVisible === true) {
        return "text";
    }
    return type;
};
var Input = React.forwardRef(function (props, ref) {
    var _a = React.useState(false), isPasswordVisible = _a[0], setPasswordVisible = _a[1];
    var id = props.id, error = props.error, value = props.value, touched = props.touched, onChange = props.onChange, onBlur = props.onBlur, suggestions = props.suggestions, type = props.type, label = props.label, autoComplete = props.autoComplete, help = props.help, disabled = props.disabled, required = props.required, padded = props.padded, autoFocus = props.autoFocus;
    return (_jsxs("div", __assign({ className: "".concat(label !== undefined ? "form-floating" : "", " w-100 ").concat(padded === true ? "mb-2" : "") }, { children: [_jsx("input", { ref: ref, name: id, placeholder: label, disabled: disabled, value: value, type: getType(type !== null && type !== void 0 ? type : "text", isPasswordVisible), "aria-required": required, "aria-describedby": help !== undefined ? "".concat(id, "-help") : undefined, className: getClassNames(type, touched, error), list: suggestions !== undefined && suggestions.length !== 0
                    ? "".concat(id, "_suggestions")
                    : undefined, autoComplete: autoComplete, onChange: onChange, onBlur: onBlur, 
                // eslint-disable-next-line
                autoFocus: autoFocus }), label !== undefined && (_jsx("label", __assign({ htmlFor: id, "aria-required": required }, { children: label }))), help !== undefined && (_jsx("small", __assign({ id: "".concat(id, "-help"), className: "form-text" }, { children: help }))), touched === true && error !== undefined && (_jsx("div", __assign({ className: "invalid-feedback" }, { children: error }), "error")), type === "password" && (_jsx("div", __assign({ className: "position-absolute end-0 top-0 bottom-0 d-flex", style: { height: 57.998 } }, { children: _jsx(Button, __assign({ variant: "easy", onClick: function () { return setPasswordVisible(!isPasswordVisible); }, title: isPasswordVisible === false ? "Anzeigen" : "Verbergen" }, { children: isPasswordVisible === false ? (_jsx(EyeRegular, { className: "text-muted" })) : (_jsx(EyeSlashRegular, { className: "text-muted" })) })) }))), suggestions !== undefined && suggestions.length !== 0 && (_jsx("datalist", __assign({ id: "".concat(id, "_suggestions") }, { children: suggestions.map(function (suggestion) { return (_jsx("option", { value: suggestion }, suggestion)); }) })))] })));
});
Input.displayName = "Input";
Input.defaultProps = {
    disabled: false,
    required: true,
    padded: true,
    type: "text",
};
export default Input;
