var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Cleave from "cleave.js/react";
import { getClassNames } from "./Input";
var DateInput = function (_a // eslint-disable-line
) {
    var id = _a.id, error = _a.error, touched = _a.touched, _b = _a.label, label = _b === void 0 ? "" : _b, _c = _a.required, required = _c === void 0 ? true : _c, _d = _a.datePattern, datePattern = _d === void 0 ? ["d", "m", "Y"] : _d, _e = _a.placeholder, placeholder = _e === void 0 ? "TT.MM.JJJJ" : _e, props = __rest(_a, ["id", "error", "touched", "label", "required", "datePattern", "placeholder"]);
    return (_jsxs("div", __assign({ className: "form-floating mb-2" }, { children: [_jsx(Cleave, __assign({ id: id, name: id, required: required, "aria-required": required, placeholder: placeholder, options: {
                    datePattern: datePattern,
                    date: true,
                    delimiter: ".",
                }, className: getClassNames("text", touched, error) }, props, { onBlur: props.onBlur })), label !== "" && (_jsx("label", __assign({ htmlFor: id, "aria-required": required }, { children: label }))), touched === true && error !== undefined && (_jsx("div", __assign({ className: "invalid-feedback" }, { children: error }), "error"))] })));
};
export default DateInput;
