import ReactGA from 'react-ga4';
import { history } from '../App';

export type CookieSettings = {
  acceptedAt: string;
  essential: boolean;
  statistics: boolean;
};

export const COOKIE_SETTINGS_LOCAL_STORAGE_KEY = 'cookie_settings';

type EventName =
  | 'cta'
  | 'view_slide'
  | 'user_update_personal_data'
  | 'user_update_password'
  | 'user_delete'
  | 'user_download_personal_data'
  | 'user_logout'
  | 'job_application_create'
  | 'job_application_preview'
  | 'job_application_download'
  | 'job_application_delete'
  | 'job_application_unlock'
  | 'payment_download'
  | 'text_template_usage'
  | 'text_template_select'
  | 'text_template_copy';

type Params = {
  type?: string;
  theme?: string;
  color?: string;
};

export const trackEvent = (name: EventName, params?: Params) => {
  ReactGA.event(name, params);
};

export const trackPageView = () => {
  ReactGA.send('pageview');
};

export const setUserId = (userId: string) => {
  const { REACT_APP_GOOGLE_ANALYTICS_ID } = process.env;

  ReactGA.gtag('config', REACT_APP_GOOGLE_ANALYTICS_ID, { user_id: userId });
  ReactGA.gtag('set', 'user_properties', { crm_id: userId });
};

export const initializeGoogleAnalytics = (): void => {
  const { REACT_APP_GOOGLE_ANALYTICS_ID } = process.env;

  if (REACT_APP_GOOGLE_ANALYTICS_ID === '') {
    return;
  }

  const cookieSettings = getCookieSettingsFromLocalStorage();

  if (cookieSettings === null) {
    return;
  }

  if (cookieSettings.statistics === false) {
    return;
  }

  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID, { gaOptions: { anonymizeIp: true } });

  // Initial page view
  trackPageView();
  // Subsequent page views
  history.listen(() => trackPageView());
};

export const optOutViaWindowProperty = (): void => {
  enableOptOutWindowProperty();

  // Although the cookie would be sufficient localStorage item should be consistent.
  const cookieSettings = getCookieSettingsFromLocalStorage();

  saveCookieSettingsToLocalStorage({
    ...cookieSettings,
    statistics: false,
  });
};

export const getCookieSettingsFromLocalStorage = (): CookieSettings | null => {
  const cookieSettings = localStorage.getItem(COOKIE_SETTINGS_LOCAL_STORAGE_KEY);

  if (cookieSettings === null) {
    return null;
  }

  return JSON.parse(cookieSettings);
};

export const saveCookieSettingsToLocalStorage = (settings: CookieSettings): void => {
  if (settings.statistics === true) {
    disableOptOutWindowProperty();
  } else {
    enableOptOutWindowProperty();
  }

  localStorage.setItem(COOKIE_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(settings));
};

const getOptOutPropertyName = (): string => {
  const { REACT_APP_GOOGLE_ANALYTICS_ID } = process.env;

  return `ga-disable-${REACT_APP_GOOGLE_ANALYTICS_ID}`;
};

// https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
const enableOptOutWindowProperty = (): void => {
  window[getOptOutPropertyName()] = true;
};

const disableOptOutWindowProperty = (): void => {
  window[getOptOutPropertyName()] = false;
};
