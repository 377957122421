import React, { Fragment } from 'react';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import CookieBanner from './CookieBanner';
import { isTokenExpired, triggerLogout } from '../Util/authentication';
import { Outlet } from 'react-router-dom';
import { ReactComponent as Gift } from '../assets/svgs/gift.svg';

// eslint-disable-next-line
const GlobalNotification = () => (
  <div className="d-flex align-items-center justify-content-center alert alert-info m-0" style={{ borderRadius: 0 }}>
    <Gift height={20} className="me-3 me-md-2 flex-shrink-0" />
    <span>
      Spare <strong>15%</strong> auf alles mit dem Gutschein Code <strong>XMAS2022</strong> den ganzen Dezember.
    </span>
  </div>
);

class Screen extends React.PureComponent {
  public componentDidMount() {
    if (isTokenExpired() === true) {
      triggerLogout();
    }
  }

  public render() {
    return (
      <Fragment>
        <Header />
        <CookieBanner />
        <main className="py-4 py-md-5">
          <Outlet />
        </main>
        <Footer />
        <Navigation />
      </Fragment>
    );
  }
}

export default Screen;
