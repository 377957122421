var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var getClassNames = function (touched, error) {
    if (touched === true && error !== undefined) {
        return "form-check-input is-invalid";
    }
    return "form-check-input";
};
var Checkbox = function (_a) {
    var id = _a.id, error = _a.error, touched = _a.touched, label = _a.label, _b = _a.required, required = _b === void 0 ? true : _b, props = __rest(_a, ["id", "error", "touched", "label", "required"]);
    return (_jsxs("div", __assign({ className: "form-check mb-2" }, { children: [_jsx("input", __assign({ id: id, name: id, type: "checkbox", required: required, className: getClassNames(touched, error) }, props)), _jsx("label", __assign({ htmlFor: id, "aria-required": required, className: "form-check-label cursor-pointer" }, { children: label })), touched === true && error !== undefined && (_jsx("div", __assign({ className: "invalid-feedback" }, { children: error }), "error"))] })));
};
export default Checkbox;
