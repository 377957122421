import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const Footer = () => (
  <footer className="bg-white fw-light border-top mt-auto">
    <div className="py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-6">
            <h4 className="h5 fw-normal">ApplyHigh</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/themes" className="text-reset">
                  Designs
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-reset">
                  Kontakt
                </Link>
              </li>
              <li>
                <Link to="/text-suggestions" className="text-reset">
                  Textvorschläge
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-6">
            <h4 className="h5">Mein Konto</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/login" className="text-reset">
                  Anmelden
                </Link>
              </li>
              <li>
                <Link to="/register" className="text-reset">
                  Registrieren
                </Link>
              </li>
              <li>
                <Link to="/password" className="text-reset">
                  Passwort vergessen
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-6">
            <h4 className="h5">Zahlungsarten</h4>
            <ul className="list-unstyled">
              <li>PayPal</li>
            </ul>
          </div>
          <div className="col-md-3 col-6">
            <h4 className="h5">Social Media</h4>
            <ul className="list-unstyled">
              <li>
                <a
                  className="text-reset"
                  href="https://www.instagram.com/applyhigh.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className="text-reset"
                  href="https://www.facebook.com/ApplyHigh-110358685004125"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="text-reset"
                  href="https://www.tiktok.com/@applyhigh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TikTok
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-light border-top">
      <div className="container">
        <ul className="list-inline border-bottom py-3 m-0">
          <li className="list-inline-item">
            <Link to="/imprint" className="text-reset">
              Impressum
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms-and-conditions" className="text-reset">
              AGB
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/data-privacy" className="text-reset">
              Datenschutzerklärung
            </Link>
          </li>
        </ul>
        <p className="py-3 mb-0">Copyright {format(new Date(), 'yyyy')} &copy; ApplyHigh</p>
      </div>
    </div>
  </footer>
);

export default Footer;
