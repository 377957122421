import React from 'react';
import { format } from 'date-fns';

import {
  CookieSettings,
  COOKIE_SETTINGS_LOCAL_STORAGE_KEY,
  initializeGoogleAnalytics,
  saveCookieSettingsToLocalStorage,
} from '../Util/analytics';
import { Button } from '@apply-high/components';
import Modal from './Modal';

type CookieSettingsModalProps = {
  isOpen: boolean;
  onAccept: () => void;
  onClose: () => void;
};

type CookieSettingsModalState = {
  settings: CookieSettings;
};

class CookieSettingsModal extends React.Component<CookieSettingsModalProps, CookieSettingsModalState> {
  constructor(props) {
    super(props);

    const settings = JSON.parse(window.localStorage.getItem(COOKIE_SETTINGS_LOCAL_STORAGE_KEY));
    const defaultSettings = {
      acceptedAt: format(new Date(), 'yyyy-MM-dd'),
      essential: true,
      statistics: false,
    };

    this.state = {
      settings: settings ?? defaultSettings,
    };
  }

  onChangeSettings = (event) => {
    const { name, checked } = event.target;

    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        [name]: checked,
      },
    }));
  };

  onAcceptAllCookies = (): void => {
    const settings: CookieSettings = {
      acceptedAt: format(new Date(), 'yyyy-MM-dd'),
      essential: true,
      statistics: true,
    };

    initializeGoogleAnalytics();
    saveCookieSettingsToLocalStorage(settings);
    this.props.onAccept();
  };

  onAcceptCookiesBySettings = (): void => {
    const settings: CookieSettings = {
      acceptedAt: format(new Date(), 'yyyy-MM-dd'),
      ...this.state.settings,
    };

    if (settings.statistics === true) {
      initializeGoogleAnalytics();
    }

    saveCookieSettingsToLocalStorage(settings);
    this.props.onAccept();
  };

  render() {
    const { settings } = this.state;
    const { isOpen, onClose } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Header>Cookie-Einstellungen</Modal.Header>
        <Modal.Body>
          <h5 className="d-flex justify-content-between align-items-center">
            <span>Unbedingt erforderliche Cookies</span>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="essential"
                name="essential"
                checked={true}
                disabled={true}
              />
              <label className="form-check-label" htmlFor="essential" style={{ cursor: 'not-allowed' }}>
                <span className="visually-hidden">Unbedingt erforderliche Cookies</span>
              </label>
            </div>
          </h5>
          <p className="text-muted mb-4">
            Notwendige Cookies helfen dabei eine Webseite nutzbar zu machen. Die Webseite kann ohne diese Cookies nicht
            funktionieren.
          </p>
          <h5 className="d-flex justify-content-between align-items-center">
            <span>Cookies für Statistiken</span>
            <div className="form-check form-switch">
              <input
                type="checkbox"
                className="form-check-input"
                id="statistics"
                name="statistics"
                onChange={this.onChangeSettings}
                checked={settings.statistics}
              />
              <label className="form-check-label" htmlFor="statistics" style={{ cursor: 'pointer' }}>
                <span className="visually-hidden">Cookies für Statistiken</span>
              </label>
            </div>
          </h5>
          <p className="text-muted mb-0">
            Diese Cookies erfassen anonyme Statistiken. Diese Cookies helfen uns zu verstehen, wie wir unsere Webseite
            noch besser machen können.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={this.onAcceptCookiesBySettings}>
            Meine Auswahl bestätigen
          </Button>
          <Button variant="strong" onClick={this.onAcceptAllCookies}>
            Alle zulassen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CookieSettingsModal;
