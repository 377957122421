var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { EyeRegular, TimesLight } from "./svgs/svg-as-component";
import Button from "./Button";
var PreviewSplitContainer = function (_a) {
    var left = _a.left, right = _a.right;
    var _b = useState(false), isPreviewVisible = _b[0], setPreviewVisible = _b[1];
    return (_jsxs("div", __assign({ className: "h-100 row g-0" }, { children: [_jsxs("div", __assign({ className: "col-12 col-md-6 p-4 overflow-y-scroll" }, { children: [left, _jsx("div", __assign({ className: "d-md-none position-fixed", 
                        // Bootstraps .p-4 class
                        style: { bottom: "1.5rem", right: "1.5rem" } }, { children: _jsx(Button, __assign({ circled: true, variant: "strong", title: "Vorschau", onClick: function () { return setPreviewVisible(true); } }, { children: _jsx(EyeRegular, {}) })) }))] })), _jsx("div", __assign({ 
                // Visibility is used prior to display to prevent scaling issues of <Preview /> component
                className: (isPreviewVisible ? "" : "visibility-hidden ") +
                    "col-12 col-md-6 visibility-md-visible bg-light bg-gradient position-fixed top-0 bottom-0 end-0" }, { children: _jsxs("div", __assign({ className: "d-flex flex-column h-100" }, { children: [_jsx("div", __assign({ className: "d-md-none d-flex justify-content-end px-2 pt-2" }, { children: _jsx(Button, __assign({ circled: true, variant: "easy", title: "Schlie\u00DFen", onClick: function () { return setPreviewVisible(false); } }, { children: _jsx(TimesLight, {}) })) })), right] })) }))] })));
};
export default PreviewSplitContainer;
