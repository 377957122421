import { SET_JOB_APPLICATIONS, UPDATE_JOB_APPLICATION, REMOVE_JOB_APPLICATION } from '../Actions/jobApplication';

const initialState = [];

const jobApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOB_APPLICATIONS: {
      const { jobApplications } = action;

      return [...jobApplications];
    }
    case UPDATE_JOB_APPLICATION: {
      const { updatedJobApplication } = action;

      return state.map((jobApplication) => {
        if (jobApplication.id === updatedJobApplication.id) {
          return updatedJobApplication;
        }

        return jobApplication;
      });
    }
    case REMOVE_JOB_APPLICATION: {
      const { jobApplicationId } = action;

      return state.filter((jobApplication) => jobApplication.id !== jobApplicationId);
    }
    default: {
      return state;
    }
  }
};

export default jobApplicationReducer;
