import React from 'react';
import { format } from 'date-fns';

import {
  COOKIE_SETTINGS_LOCAL_STORAGE_KEY,
  initializeGoogleAnalytics,
  saveCookieSettingsToLocalStorage,
} from '../Util/analytics';
import { Button } from '@apply-high/components';
import CookieSettingsModal from './CookieSettingsModal';
import './CookieBanner.scss';

type CookieBannerState = {
  hasSettings: boolean;
  isModalOpen: boolean;
};

class CookieBanner extends React.PureComponent<unknown, CookieBannerState> {
  constructor(props) {
    super(props);

    this.state = {
      hasSettings: window.localStorage.getItem(COOKIE_SETTINGS_LOCAL_STORAGE_KEY) !== null,
      isModalOpen: false,
    };
  }

  render() {
    const { hasSettings, isModalOpen } = this.state;

    if (hasSettings) {
      return null;
    }

    return (
      <>
        <div className={`CookieBanner ${hasSettings ? 'CookieBanner--accepted' : ''}`}>
          <div className="h4">Wir verwenden Cookies</div>
          <p className="text-muted">
            Wir nutzen auf unserer Webseite personenbezogene Daten, um dein Nutzererlebnis deutlich zu verbessern.
            Einige sind essenziell, andere helfen uns, die Inhalte unserer Webseite zu optimieren.
          </p>
          <div className="d-grid d-md-flex gap-2">
            <Button onClick={() => this.setState({ isModalOpen: true })}>Cookie-Einstellungen</Button>
            <Button
              variant="strong"
              onClick={() => {
                initializeGoogleAnalytics();
                saveCookieSettingsToLocalStorage({
                  acceptedAt: format(new Date(), 'yyyy-MM-dd'),
                  essential: true,
                  statistics: true,
                });
                this.setState({ hasSettings: true });
              }}
            >
              Alle Cookies zulassen
            </Button>
          </div>
        </div>
        <CookieSettingsModal
          isOpen={isModalOpen}
          onAccept={() => this.setState({ hasSettings: true })}
          onClose={() => this.setState({ isModalOpen: false })}
        />
      </>
    );
  }
}

export default CookieBanner;
