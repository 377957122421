import React from 'react';
import { ReactComponent as Pulse } from '../assets/svgs/loading-pulse.svg';
import './ScreenLoader.scss';

const ScreenLoader = () => (
  <div className="ScreenLoader">
    <Pulse className="ScreenLoader__svg" />
  </div>
);

export default ScreenLoader;
