var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var SvgArrowRightLight = function (props) { return (_jsx("svg", __assign({ "aria-hidden": "true", "data-prefix": "fal", "data-icon": "arrow-right", className: "arrow-right-light_svg__svg-inline--fa arrow-right-light_svg__fa-arrow-right arrow-right-light_svg__fa-w-14", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512", role: "img" }, props, { children: _jsx("path", { fill: "currentColor", d: "m216.464 36.465-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z" }) }))); };
export default SvgArrowRightLight;
