var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var SvgChevronDownLight = function (props) { return (_jsx("svg", __assign({ "aria-hidden": "true", "data-prefix": "fal", "data-icon": "chevron-down", className: "chevron-down-light_svg__svg-inline--fa chevron-down-light_svg__fa-chevron-down chevron-down-light_svg__fa-w-14", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512", role: "img" }, props, { children: _jsx("path", { fill: "currentColor", d: "m443.5 162.6-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z" }) }))); };
export default SvgChevronDownLight;
