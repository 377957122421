var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Button from "./Button";
var Progress = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: "position-relative d-flex justify-content-between m-auto" }, { children: React.Children.map(children, function (child, index) {
            return React.cloneElement(child, {
                isFirst: index === 0,
                isLast: index === children.length - 1,
            });
        }) })));
};
var Step = function (_a) {
    var text = _a.text, icon = _a.icon, onClick = _a.onClick, isActive = _a.isActive, isDone = _a.isDone, isFirst = _a.isFirst, isLast = _a.isLast;
    return (_jsxs("div", __assign({ className: "position-relative pb-4" }, { children: [_jsx(Button, __assign({ circled: true, variant: isActive || isDone ? "strong" : "outline", onClick: onClick }, { children: icon })), _jsx("small", __assign({ className: "position-absolute bottom-0 text-muted ".concat(isActive ? "" : "d-none d-md-block", " ").concat(isFirst ? "start-0" : "", " ").concat(isLast ? "end-0" : ""), style: __assign({ whiteSpace: "nowrap" }, (isFirst === false && isLast === false
                    ? { left: "50%", transform: "translateX(-50%)" }
                    : {})) }, { children: text }))] })));
};
var Divider = function (_a) {
    var isActive = _a.isActive;
    return (_jsx("div", __assign({ className: "position-relative w-100 mt-3", style: { height: 2, background: "var(--bs-gray-400)" } }, { children: _jsx("div", { className: "position-absolute bg-primary ".concat(isActive ? "w-100" : "w-0"), style: { height: 2 } }) })));
};
Progress.Step = Step;
Progress.Divider = Divider;
export default Progress;
