import React from 'react';
import { NormalizedUser } from '@apply-high/interfaces';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'bootstrap/js/src/dropdown';

import Logo from './Logo';
import NavLink from './NavLink';
import Dropdown from './Dropdown';
import { ReactComponent as ChevronDown } from '../assets/svgs/chevron-down-light.svg';
import withRouter, { WithRouterProps } from '../Screens/withRouter';
import { triggerLogout } from '../Util/authentication';
import './Header.scss';

type DropdownProps = {
  firstName: string;
  lastName: string;
};

const UserDropdown = ({ firstName, lastName }: DropdownProps) => (
  <Dropdown
    id="user-dropdown"
    toggle={
      <>
        <strong className="me-2">{`${firstName} ${lastName}`}</strong>
        <ChevronDown height={16} />
      </>
    }
    items={[
      {
        as: 'Link',
        text: 'Meine Bewerbungen',
        to: '/applications',
      },
      {
        as: 'Link',
        text: 'Meine Bestellungen',
        to: '/payments',
      },
      {
        as: 'Link',
        text: 'Mein Konto',
        to: '/account',
      },
      {
        as: 'button',
        text: 'Abmelden',
        onClick: () => triggerLogout(true),
      },
    ]}
  />
);

type HeaderProps = WithRouterProps & {
  user: NormalizedUser & { token?: string };
};

const Header = ({ user }: HeaderProps) => (
  <header className="Header position-relative border-bottom">
    <nav className="container d-flex justify-content-between align-items-center">
      <Link to="/">
        <span className="visually-hidden">Zur Startseite</span>
        <Logo slogan={true} className="Header__logo" />
      </Link>
      <div className="d-none d-sm-flex align-items-center gap-2">
        <NavLink className="Header__link nav-link" to="/themes">
          Designs
        </NavLink>
        <NavLink className="Header__link nav-link" to="/contact">
          Kontakt
        </NavLink>
        <div className="vr" />
        {user.token === null ? (
          <NavLink to="/login" className="Header__link nav-link">
            Anmelden
          </NavLink>
        ) : (
          <UserDropdown firstName={user.firstName} lastName={user.lastName} />
        )}
      </div>
    </nav>
  </header>
);

const mapStateToPros = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToPros, null)(Header));
