var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Divider = function (_a) {
    var _b = _a.text, text = _b === void 0 ? "oder" : _b;
    return (_jsxs("div", __assign({ className: "position-relative my-4" }, { children: [_jsx("hr", { className: "m-0" }), _jsx("span", __assign({ className: "position-absolute top-50 start-50 text-uppercase text-muted bg-white p-1", style: { transform: "translate(-50%, -50%)" } }, { children: text }))] })));
};
export default Divider;
