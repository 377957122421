import { combineReducers } from 'redux';

import userReducer from './userReducer';
import jobApplicationReducer from './jobApplicationReducer';

const combineReducer = combineReducers({
  user: userReducer,
  jobApplications: jobApplicationReducer,
});

export default combineReducer;
