import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as List } from '../assets/svgs/list-ul-duotone.svg';
import { ReactComponent as Brush } from '../assets/svgs/paint-brush-alt-duotone.svg';
import { ReactComponent as User } from '../assets/svgs/user-circle-duotone.svg';
import './Navigation.scss';

const getActiveClass = (pathname: string, regex: RegExp) => {
  if (regex.test(pathname)) {
    return 'active';
  }

  return undefined;
};

const Navigation = () => {
  const location = useLocation();

  return (
    <nav className="Navigation">
      <Link to="/themes" className={getActiveClass(location.pathname, /\/(themes|themes\/[a-z])/)}>
        <Brush />
        <span>Designs</span>
      </Link>
      <Link to="/applications" className={getActiveClass(location.pathname, /\/(applications)/)}>
        <List />
        <span>Bewerbungen</span>
      </Link>
      <Link to="/account" className={getActiveClass(location.pathname, /\/(login|register|account)/)}>
        <User />
        <span>Konto</span>
      </Link>
    </nav>
  );
};

export default Navigation;
