var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ArrowRightLight } from "./svgs/svg-as-component";
import Button from "./Button";
var DIN_A4_HEIGHT_IN_PX = 1128;
var DIN_A4_WIDTH_IN_PX = 798;
var Preview = /** @class */ (function (_super) {
    __extends(Preview, _super);
    function Preview(props) {
        var _this = _super.call(this, props) || this;
        _this.updatePageIndicator = function (event) {
            var origin = event.origin, data = event.data;
            if (origin !== window.origin) {
                return;
            }
            if (data.type && data.totalPages && data.activePage) {
                _this.setState({ pageIndicator: data });
            }
        };
        _this.updateScaleFactor = function () {
            var controlsHeightInPx = _this.controlsRef.current.getBoundingClientRect().height;
            var _a = _this.containerRef.current.getBoundingClientRect(), containerWidthInPx = _a.width, containerHeightInPx = _a.height;
            var spacingInPx = 40;
            var ratio = parseFloat(Math.min((containerWidthInPx - spacingInPx) / DIN_A4_WIDTH_IN_PX, (containerHeightInPx - spacingInPx - controlsHeightInPx) /
                DIN_A4_HEIGHT_IN_PX).toFixed(3));
            // iframe
            _this.iFrameRef.current.style.position = "absolute";
            _this.iFrameRef.current.style.top = "20px";
            _this.iFrameRef.current.style.left = "50%";
            _this.iFrameRef.current.style.transform = "scale(".concat(ratio, ") translate(-50%, 0)");
            // controls
            _this.controlsRef.current.style.width = "".concat(DIN_A4_WIDTH_IN_PX * ratio, "px");
            _this.controlsRef.current.style.margin = "0 auto";
            _this.controlsRef.current.style.top = "".concat(DIN_A4_HEIGHT_IN_PX * ratio + 24, "px");
        };
        _this.onPreviousButtonClick = function () {
            // Handled by preview.js in iframe source
            _this.iFrameRef.current.contentWindow.postMessage("previous");
        };
        _this.onNextButtonClick = function () {
            // Handled by preview.js in iframe source
            _this.iFrameRef.current.contentWindow.postMessage("next");
        };
        _this.state = {
            pageIndicator: null,
        };
        _this.containerRef = React.createRef();
        _this.iFrameRef = React.createRef();
        _this.controlsRef = React.createRef();
        return _this;
    }
    Preview.prototype.componentDidMount = function () {
        this.updateScaleFactor();
        window.addEventListener("resize", this.updateScaleFactor);
        window.addEventListener("message", this.updatePageIndicator);
    };
    Preview.prototype.componentWillUnmount = function () {
        window.removeEventListener("resize", this.updateScaleFactor);
        window.removeEventListener("message", this.updatePageIndicator);
    };
    Preview.prototype.render = function () {
        var pageIndicator = this.state.pageIndicator;
        var _a = this.props, html = _a.html, previewTag = _a.previewTag;
        var isLoading = html === null || pageIndicator === null;
        return (_jsxs("div", __assign({ className: "position-relative h-100 w-100", ref: this.containerRef }, { children: [_jsx("iframe", { srcDoc: html, ref: this.iFrameRef, style: {
                        height: DIN_A4_HEIGHT_IN_PX,
                        width: DIN_A4_WIDTH_IN_PX,
                        transformOrigin: "top left",
                        overflow: "hidden",
                    }, "data-preview-tag": previewTag, title: "Vorschau", className: isLoading ? "placeholder placeholder-wave" : undefined }), _jsxs("div", __assign({ className: "position-absolute start-0 end-0 d-flex align-items-center justify-content-between", ref: this.controlsRef }, { children: [_jsx(Button, __assign({ circled: true, variant: "easy", size: "small", title: "Vorherige Seite", disabled: isLoading || (pageIndicator === null || pageIndicator === void 0 ? void 0 : pageIndicator.activePage) === 1, onClick: this.onPreviousButtonClick }, { children: _jsx(ArrowRightLight, { style: { transform: "rotate(180deg)" } }) })), _jsx("small", __assign({ className: isLoading ? "placeholder placeholder-wave col-4" : undefined }, { children: pageIndicator &&
                                "Seite ".concat(pageIndicator.activePage, " / ").concat(pageIndicator.totalPages) })), _jsx(Button, __assign({ circled: true, variant: "easy", size: "small", title: "N\u00E4chste Seite", disabled: isLoading ||
                                (pageIndicator === null || pageIndicator === void 0 ? void 0 : pageIndicator.activePage) === (pageIndicator === null || pageIndicator === void 0 ? void 0 : pageIndicator.totalPages), onClick: this.onNextButtonClick }, { children: _jsx(ArrowRightLight, {}) }))] }))] })));
    };
    return Preview;
}(React.Component));
export default Preview;
