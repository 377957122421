import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import App from './App';

const { REACT_APP_SENTRY_DSN, npm_package_version } = process.env;

if (REACT_APP_SENTRY_DSN !== '') {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    release: npm_package_version,
    beforeSend(event) {
      if (event.user) {
        delete event.user.ip_address;
        delete event.user.email;
      }

      return event;
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
