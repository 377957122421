import React from 'react';
import Helmet from 'react-helmet';

const globalKeywords = [
  'bewerben',
  'Bewerbung',
  'Bewerbungen',
  'professionelle Bewerbungen',
  'Anschreiben',
  'Lebenslauf',
];

type MetaTag = {
  name: string;
  content: string;
};

type SEOProps = {
  title: string;
  description: string;
  keywords?: Array<string>;
  imageSrc?: string;
};

const generateOpenGraphTags = (title: string, description: string, imageSrc: string): Array<MetaTag> => {
  return [
    {
      name: 'og:type',
      content: 'description',
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:image',
      content: imageSrc,
    },
  ];
};

const generateTwitterTags = (title: string, description: string, imageSrc: string): Array<MetaTag> => {
  return [
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: imageSrc,
    },
  ];
};

export const getPublicUrl = (): string => {
  const { REACT_APP_APP_URL, PUBLIC_URL } = process.env;

  return REACT_APP_APP_URL + PUBLIC_URL;
};

const SEO = ({ title, description, imageSrc, keywords = [] }: SEOProps) => (
  <Helmet
    title={title}
    titleTemplate="%s | ApplyHigh"
    meta={[
      {
        name: 'description',
        content: description,
      },
      {
        name: 'keywords',
        content: [...globalKeywords, ...keywords].join(', '),
      },
      ...generateOpenGraphTags(title, description, imageSrc),
      ...generateTwitterTags(title, description, imageSrc),
    ]}
  />
);

const { REACT_APP_BUILD_VERSION } = process.env;

SEO.defaultProps = {
  title: 'Dein Aufstieg ins Berufsleben',
  description: 'Gestalte Deine individuelle Bewerbung in nur 10 Minuten.',
  imageSrc: `${getPublicUrl()}/meta/default.jpg?v=${REACT_APP_BUILD_VERSION}`,
  keywords: [],
};

export default SEO;
