import React from 'react';
import { useLocation, useNavigate, useParams, Location, NavigateFunction, Params } from 'react-router-dom';

export type WithRouterProps = {
  location: Location;
  navigate: NavigateFunction;
  params: Params;
};

export default function withRouter(Component) {
  function ComponentWithRouterProps(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterProps;
}
