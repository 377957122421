import React from 'react';
import ReactDOM from 'react-dom';
import { default as BootstrapModal } from 'bootstrap/js/src/modal';
import { v4 as uuid } from 'uuid';

const ModalHeader = ({ children, hasCloseButton = true }) => (
  <div className="modal-header p-3 p-md-4">
    <div className="modal-title h4">{children}</div>
    {hasCloseButton && <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Schließen" />}
  </div>
);

const ModalBody = ({ children }) => <div className="modal-body p-3 p-md-4">{children}</div>;

const ModalFooter = ({ children }) => <div className="modal-footer p-3 p-md-4">{children}</div>;

const getDialogClassNames = (props: ModalProps) => {
  const classNames = ['modal-dialog'];

  if (props.size !== undefined) {
    classNames.push(`modal-${props.size}`);
  }

  if (props.isCentered === true) {
    classNames.push('modal-dialog-centered');
  }

  if (props.isFullscreen === true) {
    classNames.push('modal-fullscreen');
  }

  return classNames.join(' ');
};

type ModalProps = React.PropsWithChildren<{
  id?: string;
  size?: 'sm' | 'lg' | 'xl';
  isOpen: boolean;
  isCentered: boolean;
  isFullscreen: boolean;
  isCloseableViaBackdrop: boolean;
  onClose: () => void;
  onOpen?: () => void;
}>;

class Modal extends React.PureComponent<ModalProps> {
  private readonly modalRef;
  private modalInstance;

  // Sub components
  public static Header = ModalHeader;
  public static Body = ModalBody;
  public static Footer = ModalFooter;

  static defaultProps = {
    id: uuid(),
    isCentered: false,
    isFullscreen: false,
    isCloseableViaBackdrop: true,
  };

  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const { isOpen, isCloseableViaBackdrop, onClose, onOpen } = this.props;

    this.modalInstance = new BootstrapModal(this.modalRef.current, {
      backdrop: isCloseableViaBackdrop ? true : 'static',
    });
    this.modalRef.current.addEventListener('hidden.bs.modal', onClose);

    if (onOpen !== undefined) {
      this.modalRef.current.addEventListener('shown.bs.modal', onOpen);
    }

    if (isOpen === true) {
      this.modalInstance.show();
    }
  }

  componentWillUnmount() {
    const { onClose, onOpen } = this.props;

    this.modalInstance.hide();
    this.modalRef.current.removeEventListener('hidden.bs.modal', onClose);

    if (onOpen !== undefined) {
      this.modalRef.current.removeEventListener('shown.bs.modal', onOpen);
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen === true && prevProps.isOpen === false) {
      this.modalInstance.show();
    }

    if (isOpen === false && prevProps.isOpen === true) {
      this.modalInstance.hide();
    }
  }

  render() {
    const { id, children } = this.props;

    return ReactDOM.createPortal(
      <div ref={this.modalRef} id={id} className="modal fade" tabIndex={-1}>
        <div className={getDialogClassNames(this.props)}>
          <div className="modal-content">{children}</div>
        </div>
      </div>,
      document.body
    );
  }
}

export default Modal;
