var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Loader from "./Loader";
var getVariantClass = function (variant) {
    var variants = {
        outline: "btn-outline-primary bg-white text-primary",
        strong: "btn-primary text-white",
        easy: "btn-link text-body",
        danger: "btn-outline-danger bg-white text-danger",
    };
    return variants[variant];
};
var getSizeClass = function (size) {
    var sizes = {
        small: "btn-sm",
        normal: "",
        big: "btn-lg",
    };
    return sizes[size];
};
var getClassNames = function (variant, size, disabled, circled, loading, fullWidth) {
    return [
        "btn position-relative text-decoration-none d-inline-flex align-items-center justify-content-center flex-shrink-0",
        getVariantClass(variant),
        getSizeClass(size),
        disabled ? "opacity-75 pe-none" : "",
        circled ? "btn-circled p-0" : "",
        loading ? "pe-none" : "",
        fullWidth ? "w-100" : "",
    ]
        .filter(function (className) { return className !== ""; })
        .join(" ");
};
var ButtonLoader = function (_a) {
    var size = _a.size, loading = _a.loading;
    var sizes = {
        small: 16,
        normal: 20,
        big: 24,
    };
    if (loading === false) {
        return null;
    }
    return (_jsx(Loader, { size: sizes[size], className: "position-absolute text-inherit" }));
};
var ContentContainer = function (_a) {
    var children = _a.children, loading = _a.loading;
    return (_jsx("span", __assign({ className: "h-100 d-inline-flex align-items-center justify-content-center ".concat(loading ? "opacity-0" : "") }, { children: children })));
};
var Button = React.forwardRef(function (props, ref) {
    var children = props.children, render = props.render, variant = props.variant, size = props.size, loading = props.loading, circled = props.circled, fullWidth = props.fullWidth, type = props.type, title = props.title, disabled = props.disabled, tabIndex = props.tabIndex, onClick = props.onClick;
    return render({
        className: getClassNames(variant, size, disabled, circled, loading, fullWidth),
        children: (_jsxs(_Fragment, { children: [_jsx(ContentContainer, __assign({ loading: loading }, { children: children })), _jsx(ButtonLoader, { size: size, loading: loading })] })),
        type: type,
        title: title,
        disabled: disabled,
        tabIndex: tabIndex,
        onClick: onClick,
    }, ref);
});
Button.displayName = "Button";
Button.defaultProps = {
    render: function (props, ref) { return _jsx("button", __assign({ ref: ref }, props)); },
    variant: "outline",
    size: "normal",
    type: "button",
    disabled: false,
    loading: false,
    circled: false,
    fullWidth: false,
};
export default Button;
