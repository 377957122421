var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getClassNames } from "./Input";
var TextArea = React.forwardRef(function (props, ref) {
    var id = props.id, error = props.error, value = props.value, touched = props.touched, heightInPx = props.heightInPx, label = props.label, required = props.required, onChange = props.onChange, onBlur = props.onBlur;
    return (_jsxs("div", __assign({ className: "form-floating mb-2" }, { children: [_jsx("textarea", { ref: ref, id: id, name: id, value: value, required: required, "aria-required": required, className: getClassNames("text", touched, error), style: { height: "".concat(heightInPx, "px") }, onChange: onChange, onBlur: onBlur }), label !== "" && (_jsx("label", __assign({ htmlFor: id, "aria-required": required }, { children: label }))), touched === true && error !== undefined && (_jsx("div", __assign({ className: "invalid-feedback" }, { children: error }), "error"))] })));
});
TextArea.displayName = "TextArea";
TextArea.defaultProps = {
    required: true,
    label: "",
};
export default TextArea;
