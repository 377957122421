import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/js/src/dropdown';

type DropdownProps = {
  id: string;
  toggle: ReactNode;
  items: Array<DropdownItem>;
};

export type DropdownItem = ButtonItem | LinkItem;

type ButtonItem = {
  as: 'button';
  text: string;
  onClick(event: React.MouseEvent): void;
};

type LinkItem = {
  as: 'Link';
  text: string;
  to: string;
};

const Dropdown = ({ id, toggle, items }: DropdownProps) => (
  <div className="dropdown">
    <button className="btn dropdown-toggle" type="button" id={id} data-bs-toggle="dropdown" aria-expanded="false">
      {toggle}
    </button>
    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby={id}>
      {items.map((item) => {
        return (
          <li key={item.text}>
            {item.as === 'Link' ? (
              <Link className="dropdown-item" to={item.to}>
                {item.text}
              </Link>
            ) : (
              <button className="dropdown-item" onClick={item.onClick}>
                {item.text}
              </button>
            )}
          </li>
        );
      })}
    </ul>
  </div>
);

export default Dropdown;
